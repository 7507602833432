/* .toast-error {
  width           : 100% !important;
  background-color: #DD1919 !important;
  background-image: none !important;
}

.toast-success {
  background-image: none !important;
  background-color: #2872AE !important;
  width           : 100% !important;
}

.toast-message {
  text-align: center !important;
}

.komunikat_error {
  height      : 24px;
  padding-left: 40px;
  background  : url("assets/img/error.svg") no-repeat left center;
}

.komunikat_success {
  height      : 24px;
  padding-left: 40px;
  background  : url("assets/img/success.svg") no-repeat left center;
}

.toast-container .ngx-toastr {
  padding: 15px 15px 15px 0px !important;
}

.ft__info {
  color         : #ffffff;
  font-size     : 12px;
  font-weight   : 500;
  font-style    : normal;
  letter-spacing: 0.86px;
  line-height   : 16px;
} */

.toast-error {
    background-image: none !important;
    background-color: #DC3545 !important;
    width: 100% !important;
    /* text-align: center; */
    /* background-image: none !important; */
    box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
    border-radius: 0px 0px 16px 16px !important;
}

.toast-success {
    background-image: none !important;
    background-color: #2C9644 !important;
    width: 100% !important;
    box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
    border-radius: 0px 0px 16px 16px !important;
}

.toast-info {
    background-image: none !important;
    background-color: #5C80BC !important;
    width: 100% !important;
    box-shadow: 0px 2px 20px rgb(0 0 0 / 20%) !important;
    border-radius: 0px 0px 16px 16px !important;
}

.info__text {
    background: url("assets/img/info.svg") no-repeat left center;
}

.toast-container .ngx-toastr {
    padding: 15px 15px 15px 0px !important;
}


/* .toast-message {
  text-align: center !important;
} */

.navbar {
    z-index: 999 !important;
}

.preloader.active {
    visibility: visible;
    opacity: 0.3 !important;
}

.preloader {
    z-index: 99999 !important;
    background-color: #fff !important;
    /* position: absolute; */
}

div:not(.error)>input[type="number"].inInput {
    border-radius: 16px !important;
    width: 105px !important;
    height: 44px !important;
    border: none !important;
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.error input[type="number"].inInput {
    border-radius: 16px !important;
    width: 105px !important;
    height: 44px !important;
    -moz-appearance: textfield;
    border-color: #ff0000 !important;
}

.set-pointer {
    cursor: pointer;
}

.toast-container .container {
    display: flex;
    align-items: center;
    justify-content: center; }
  
    .btn__edit{
      z-index: initial;
    }

    .modal-content { 
  border: none !important; 
    justify-content: center;
}

.btn__edit {
    z-index: initial;
}

.conf-p
{
  z-index: 99 !important;
}

.brandKarty{
  width: 64px; 
}

 @media (min-width: 920px) {
      body::before {
        height: 140px !important; } }

        .btn__green { 
    width: 90%;
}

@media only screen and (min-width: 968px){
.navbar__container { 
    padding: 0px 0 0px !important; 
}}

.btn__add2 {
   
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #558564 url("data:image/svg+xml,%3Csvg fill='none' height='14' viewbox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z' fill='white'%3E %3C/path%3E %3C/svg%3E") no-repeat center center;
   }
  
    .btn__add2 {
      background-position: 18px center;
      padding-left: 45px;
      width: auto;
      height: 48px;
      padding-right: 15px;
      border-radius: 22px;
    margin:0 auto;
    display:block; }
      .btn__add2 span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 1px;
        color: #FFFFFF; }  

          .piec-linii {
       
        text-overflow: ellipsis;
        overflow: hidden; 
        line-height: 25px;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical; 
    }